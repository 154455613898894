import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CardMedia,
    IconButton, InputAdornment, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AccountBalance, Add,
    AddCircle, AddShoppingCart,
    CurrencyExchange,
    DesignServices, Edit,
    Image,
    MoreVert,
    Paid, Remove,
    Tag
} from "@mui/icons-material";
import PrecioCreateDialog from "../precio/precio-create/precio-create-dialog";
import {useState} from "react";
import PhotoEdit from "../photo-edit/photo-edit";
import {loadFile} from "../../../helpers/files";
import LoteEditDialog from "../../lote/lote-edit/lote-edit-dialog";
import ProductoEditDialog from "../producto-edit/producto-edit-dialog";
import tools from "../../../assets/img/tools.png";
import ProductoSaleAddDialog from "./producto-sale-add-dialog";
import {getProductosThunk} from "../../../redux/productos/productos.thunk";
import {connect} from "react-redux";
import {addProductoSale} from "../../../redux/productos/productos.actions";

const ProductoSaleItem = (props) => {
    const {item, setResetInput} = props;
    const [openPrecio, setOpenPrecio] = useState(false);
    const [openPhoto, setOpenPhoto] = useState(false);
    const [openSale, setOpenSale] = useState(false);
    const [cantidad, setCantidad] = useState(1);

    const onAddPrecio = () => {
        setOpenPrecio(true);
    }

    const onClosePrecio = async () => {
        setOpenPrecio(false);
    }

    const onEditPhoto = () => {
        setOpenPhoto(true);
    }

    const onPushCart = () => {
        if(props.productosSale.find((x) => { return x.producto.id === item.id})) {
            const newCarro = props.productosSale.map((x)=> {
                return x.producto.id === item.id ?
                    ({...x, cantidad: Number(x.cantidad) + Number(cantidad)})
                    : x
            })
            props.addProductoSale(newCarro);
        } else {
            props.addProductoSale([...props.productosSale, {
                cantidad,
                producto: item
            }]);
        }
        setResetInput(true);
        setCantidad(1);
    }

    const onCloseSale = async () => {
        setOpenSale(false);
    }

    const onClosePhoto = async () => {
        setOpenPhoto(false);
    }

    const onChangeCantidad = (e) => {
        if (!isNaN(e.target.value)) {
            setCantidad(e.target.value);
        }
    }

    return (
        <div>
        <Card className="h-100 position-relative">
            <CardMedia
                component="img"
                height="230"
                image={loadFile(item.foto) || tools}
            />
            <CardContent>
                <Typography variant="h6" className="text-center my-1">
                    <b>{item.nombre}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="my-2 text-description">
                    <b><em>{item.descripcion || 'Sin descripción'}</em></b>
                </Typography>
                <Typography variant="body2" color="text.secondary" className="d-flex justify-content-between my-1">
                    <Tooltip title="Precio">
                        <AccountBalance/>
                    </Tooltip>
                    <b>{item.marca || 'SIN MARCA'}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary"
                            className="d-flex justify-content-between align-items-center text-success my-1">
                    <Tooltip title="Precio Venta">
                        <Paid/>
                    </Tooltip>
                    {item.precio ? <b><span className="h4">{item.precio}</span>{ ' Bs. / ' + item.medida} </b> : <em>SIN PRECIO</em>}
                </Typography>
            </CardContent>
            <CardActions disableSpacing className="d-block">
                <div className="w-100 d-flex justify-content-center">
                    <TextField
                        label={`${item.medida}'s`}
                        id="outlined-start-adornment"
                        type="number"
                        onChange={onChangeCantidad}
                        size="small"
                        sx={{ m: 1, width: '20ch' }}
                        value={cantidad}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">

                                        <IconButton
                                            onClick={() => cantidad > 1 ? setCantidad(Number(cantidad) - 1) : null}
                                            color="primary"
                                            edge="start">
                                            <Remove/>
                                        </IconButton>

                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position="end">

                                        <IconButton
                                            onClick={() => setCantidad(Number(cantidad) + 1)}
                                            color="primary"
                                            edge="end">
                                            <Add/>
                                        </IconButton>
                                </InputAdornment>,
                        }}
                    />
                </div>
                <Button disableElevation variant="contained" aria-label="Añadir al carrito" onClick={() => onPushCart()} className="bg-success btn-success w-100">
                    <AddShoppingCart/>
                </Button>
            </CardActions>
        </Card>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        productosSale: state.productos.productosSale,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        addProductoSale: (producto) => dispatch(addProductoSale(producto)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductoSaleItem);
