import {connect} from "react-redux";
import {
    Avatar, Checkbox,
    CircularProgress, FormControlLabel,
    IconButton, InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Paper,
    TextField,
    Typography
} from "@mui/material";
import {addProductoSale, resetProductoSale} from "../../../redux/productos/productos.actions";
import {loadFile} from "../../../helpers/files";
import {Add, Delete} from "@mui/icons-material";
import {useEffect, useState} from "react";
import ProductoSaleCreateItem from "./producto-sale-create-item";
import AutocompleteFieldWrapper from "../../layouts/forms/autocomplete-field-wrapper";
import {getClientesThunk} from "../../../redux/clientes/clientes.thunk";
import {getCliente, updateCliente} from "../../../redux/clientes/clientes.actions";
import MarcaCreateDialog from "../../marca/marca-create/marca-create-dialog";
import ClienteCreateDialog from "../../cliente/cliente-create/cliente-create-dialog";
import AutocompleteNoFormik from "../../layouts/forms/autocomplete-no-formik";
import InputBaseNoFormik from "../../layouts/forms/input-base-no-formik";
import {storeVentaThunk, updateVentaThunk} from "../../../redux/ventas/ventas.thunk";
import {getVenta} from "../../../redux/ventas/ventas.actions";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";

const ProductoSaleCreate = (props) => {

    const [statusCliente, setStatusCliente] = useState('initial');
    const [openCliente, setOpenCliente] = useState(false);
    const [cancelado, setCancelado] = useState(0);
    const navigate = useNavigate();

    const getTotal = () => {
        let total = 0;
        props.productosSale.map((item) => {
            if (item.subtotal) {
                if (item.precioChanged) {
                    total += item.cantidad * item.producto.precio;
                } else {
                    total += item.subtotal;
                }
            } else {
                total += item.cantidad * item.producto.precio;
            }
        })
        return total;
    }

    useEffect(() => {
        setCancelado(getTotal());
    }, [])

    const vender = async () => {
        const fecha = moment().format('YYYY-MM-DD');
        const venta = {
            total: getTotal(),
            fecha,
            entregado: true,
        };
        const productos = props.productosSale.map((item) => {
            return {
                ...item,
                fecha,
                entregado: true,
            }
        });
        const pagoVenta = {
            pago: cancelado > getTotal() ? getTotal() : cancelado,
            fecha,
            detalle: ''
        }
        const obj = {venta, productos, pagoVenta, clienteId: props.cliente?.id || null};
        // console.log(obj);
        try {
            await props.storeVenta(obj);
            console.log('Vendido', props.venta);
            toastr.success('Venta registrada exitosamente.')

            props.resetProductosSale();
            props.resetCliente();
            navigate('/sales-print')
            // navigate('/sales');
        } catch (e) {
            toastr.error('UPS.. algo salio mal :( Intentalo otra vez.')
        }
    }

    const actualizarVenta = async () => {
        const fecha = moment().format('YYYY-MM-DD');
        const venta = {
            total: getTotal(),
            entregado: true,
        };
        const productos = props.productosSale.map((item) => {
            return {
                ...item,
                fecha,
                entregado: true,
            }
        });
        const pagoVenta = {
            pago: cancelado > getTotal() ? getTotal() : cancelado,
            fecha,
            detalle: ''
        }
        const obj = {venta, productos, pagoVenta, clienteId: props.cliente?.id || null};
        // console.log(obj);
        try {
            await props.updateVenta(obj, props.venta.id);
            console.log('Venta actualizada', props.venta);
            toastr.success('Venta actualizada exitosamente.')

            props.resetProductosSale();
            props.resetCliente();
            props.resetVenta();
            navigate('/ventas')
            // navigate('/sales');
        } catch (e) {
            toastr.error('UPS.. algo salio mal :( Intentalo otra vez.')
        }
    }

    const onCancelEdit = () => {

        props.resetProductosSale();
        props.resetCliente();
        props.resetVenta();
        navigate('/ventas')
    }

    useEffect(() => {
        const fun = async () => {
          await props.getClientes();
        }
        fun();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="d-grid mb-4">
                        {/*<button className="btn btn-lg btn-danger" onClick={() => navigate('/sales-print')}>IMPRIMIR NOTA</button>*/}
                    </div>
                    <div className="d-grid mb-4">
                        {props.venta ?
                            <button className="btn btn-lg btn-success" onClick={() => actualizarVenta()}>ACTUALIZAR VENTA</button>
                        : <button className="btn btn-lg btn-success" onClick={() => vender()}>VENDER</button>}
                    </div>
                    <div className="d-grid mb-4">
                        {props.venta && <button className="btn btn-lg btn-outline-secondary" onClick={() => onCancelEdit()}>CANCELAR</button>}
                    </div>
                </div>
                <div className="col-md-8 offset-md-2">
                    {statusCliente === 'loading' ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        <div className="my-3 position-relative">
                            <AutocompleteNoFormik name="clientes"
                                                  label="Clientes"
                                                  value={props.cliente}
                                                  setItem={props.updateCliente}
                                                  labelValue="nombre"
                                                  idValue="nit6"
                                                  options={props.clientes}/>
                            <div className="btn-top-right">
                                <IconButton className="bg-success text-white p-1"
                                            onClick={() => setOpenCliente(true)}>
                                    <Add fontSize="small"/>
                                </IconButton>
                            </div>
                        </div>}
                    <ClienteCreateDialog open={openCliente} onClose={() => setOpenCliente(false)}/>
                </div>
                <div className="col-md-8 offset-md-2">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <FormControlLabel control={<Checkbox defaultChecked
                                                                 onChange={(e) => {
                                                                     this.setState({order: e.target.checked ? 'asc' : 'desc'})}}/>}
                                              label={'ENTREGADO'} />
                        </div>
                        <div className="row w-auto">
                            <div className="col-6">
                                <TextField className="mt-2" type="number" step="0.5" value={cancelado} onChange={(e) => setCancelado(e.target.value)} label="MONTO CANCELADO" />
                            </div>
                            <div className="col-6">
                                <TextField className="mt-2" disabled type="number" step="0.5" value={cancelado - getTotal()} label="CAMBIO" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <List>
                {props.productosSale.map((item, index) =>
                    <ProductoSaleCreateItem item={item} key={index}/>
                )}
                <ListItem>
                    <ListItemText primary={`TOTAL: `}/>
                    <Typography variant="h5" color="text.primary" className="ms-3">
                        {`${getTotal()} Bs.`}
                    </Typography>
                </ListItem>
            </List>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        productosSale: state.productos.productosSale,
        cliente: state.clientes.cliente,
        clientes: state.clientes.clientes,
        venta: state.ventas.venta
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProductoSale: (producto) => dispatch(addProductoSale(producto)),
        resetProductosSale: () => dispatch(resetProductoSale()),
        getClientes: () => dispatch(getClientesThunk()),
        resetCliente: () => dispatch(getCliente(null)),
        updateCliente: (data) => dispatch(updateCliente(data)),
        storeVenta: (data) => dispatch(storeVentaThunk(data)),
        updateVenta: (data, id) => dispatch(updateVentaThunk(data, id)),
        resetVenta: () => dispatch(getVenta(null)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductoSaleCreate);
