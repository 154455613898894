import {connect} from "react-redux";
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography} from "@mui/material";
import {addProductoSale} from "../../../redux/productos/productos.actions";
import {loadFile} from "../../../helpers/files";
import {Delete} from "@mui/icons-material";
import {useEffect, useState} from "react";

const ProductoSaleCreateItem = (props) => {
    console.log("PROOOOO", props.item)
    const [editCantidad, setEditCantidad] = useState(false);
    const [editPrecio, setEditPrecio] = useState(false);
    const [precioChanged, setPrecioChanged] = useState(false);

    const {item} = props;
    const deleteProducto = () => {
        const newProductos = props.productosSale.filter((prod) => {
            return item.producto.id !== prod.producto.id;
        })
        props.updateProductoSale(newProductos);
    }

    useEffect(() => {
        if (!item.precioChanged) {
            const value = item.subtotal ? Math.round(item.subtotal / item.cantidad * 100) / 100 : item.producto.precio;
            const newItem = {...item, producto: {...item.producto, precio: value}, precioChanged: true};
            const newProductos = props.productosSale?.map((prod) => {
                return item.producto.id !== prod.producto.id ? prod : newItem;
            })
            props.updateProductoSale(newProductos);
        }
    }, [])

    const updateCantidad = (e) => {
        const value = e.target.value;
        const newItem = {...item, cantidad: value};

        const newProductos = props.productosSale?.map((prod) => {
            return item.producto.id !== prod.producto.id ? prod : newItem;
        })
        props.updateProductoSale(newProductos);

    }

    const updatePrecio = (e) => {
        const value = e.target.value;
        const newItem = {...item, producto: {...item.producto, precio: value}, precioChanged: true};

        const newProductos = props.productosSale?.map((prod) => {
            return item.producto.id !== prod.producto.id ? prod : newItem;
        })
        props.updateProductoSale(newProductos);

    }
    const precioValue = () => {
        if (item.precioChanged) {
            return item.producto.precio;
        }
        return item.subtotal ? Math.round(item.subtotal / item.cantidad * 100) / 100 : item.producto.precio;
    }

    return (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            alt={item.producto.nombre}
                            src={loadFile(item.producto.foto)}/>
                    </ListItemAvatar>

                    {!editCantidad ? <ListItemText onClick={() => {console.log(item); setEditCantidad(!editCantidad)}} primary={<span>{item.cantidad}<small className="ms-1 sm-text">{item.producto.medida}</small></span>} className="me-2"/> :
                    <TextField
                        label="Cantidad"
                        autoFocus={editCantidad}
                        id="outlined-size-small"
                        defaultValue={item.cantidad}
                        onChange={updateCantidad}
                        onClick={() => setEditCantidad(!editCantidad)}
                        size="small"
                        className="w-auto me-2"
                        type="number"
                        InputProps={{
                            endAdornment: <small>{item.producto.medida}</small>
                        }}
                    />}

                    <ListItemText primary={item.producto.nombre} secondary={<small>{item.producto.descripcion}</small>}/>

                    {!editPrecio ?<ListItemText onClick={() => setEditPrecio(!editPrecio)} secondary={<small>{precioValue()}Bs.</small>}/> :
                        <TextField
                            label="Cantidad"
                            autoFocus={editPrecio}
                            id="outlined-size-small"
                            defaultValue={precioValue()}
                            onChange={updatePrecio}
                            onClick={() => setEditPrecio(!editPrecio)}
                            size="small"
                            className="w-auto me-2"
                            type="number"
                            InputProps={{
                                endAdornment: <small>Bs</small>
                            }}
                        />}

                    <Typography variant="body2" color="text.primary" className="ms-3">
                        <span className="">{`${precioValue() * item.cantidad} Bs.`}</span>
                        <IconButton className="ms-2" onClick={() => deleteProducto()}>
                            <Delete color="error"/>
                        </IconButton>
                    </Typography>
                </ListItem>

    )
}

const mapStateToProps = (state) => {
    return {
        productosSale: state.productos.productosSale,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProductoSale: (producto) => dispatch(addProductoSale(producto)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductoSaleCreateItem);
